import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard-module/dashboard-module.module').then( m => m.DashboardModuleModule)
  },
  {
    path: '',
    loadChildren: () => import('./login-module/login-module.module').then( m => m.LoginModuleModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp-module/otp-module.module').then( m => m.OtpModuleModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user-module/user-module.module').then( m => m.UserModuleModule)
  },
  {
    path: 'send-message',
    loadChildren: () => import('./rcs-module/rcs-module.module').then( m => m.RcsModuleModule)
  },

  

  {
    path: 'rcs-reports',
    loadChildren: () => import('./rcs-reports/rcs-reports.module').then( m => m.RcsReportsPageModule)
  },
  {
    path: 'rcs-today-reports',
    loadChildren: () => import('./rcs-today-reports/rcs-today-reports.module').then( m => m.RcsTodayReportsPageModule)
  },
  {
    path: 'send-sms',
    loadChildren: () => import('./sms-module/sms-module.module').then( m => m.SmsModuleModule)
  },

  {
    path: 'sms-reports',
    loadChildren: () => import('./sms-reports/sms-reports.module').then( m => m.SmsReportsPageModule)
  },
  {
    path: 'sms-archieve-reports',
    loadChildren: () => import('./sms-archieve-reports/sms-archieve-reports.module').then( m => m.SmsArchieveReportsPageModule)
  },
  {
    path: 'sms-campaign-reports',
    loadChildren: () => import('./sms-campaign-reports/sms-campaign-reports.module').then( m => m.SmsCampaignReportsPageModule)
  },
  {
    path: 'sms-delivery-reports',
    loadChildren: () => import('./sms-delivery-reports/sms-delivery-reports.module').then( m => m.SmsDeliveryReportsPageModule)
  },
  {
    path: 'sms-credit-history',
    loadChildren: () => import('./sms-credit-history/sms-credit-history.module').then( m => m.SmsCreditHistoryPageModule)
  },

  {
    path: 'rcs-summary',
    loadChildren: () => import('./rcs-summary/rcs-summary.module').then( m => m.RcsSummaryPageModule)
  },
  {
    path: 'rcs-archieve-reports',
    loadChildren: () => import('./rcs-archieve-reports/rcs-archieve-reports.module').then( m => m.RcsArchieveReportsPageModule)
  },
  {
    path: 'whatsapp',
    loadChildren: () => import('./whatsapp-module/whatsapp-module.module').then( m => m.WhatsappModuleModule)
  },
  {
    path: 'whatsapp-archieve-reports',
    loadChildren: () => import('./whatsapp-archieve-reports/whatsapp-archieve-reports.module').then( m => m.WhatsappArchieveReportsPageModule)
  },
  {
    path: 'whatsapp-summary-reports',
    loadChildren: () => import('./whatsapp-summary-reports/whatsapp-summary-reports.module').then( m => m.WhatsappSummaryReportsPageModule)
  },
  {
    path: 'whatsapp-todays-reports',
    loadChildren: () => import('./whatsapp-todays-reports/whatsapp-todays-reports.module').then( m => m.WhatsappTodaysReportsPageModule)
  },
  {
    path: 'whatsapp-reports',
    loadChildren: () => import('./whatsapp-reports/whatsapp-reports.module').then( m => m.WhatsappReportsPageModule)
  },
  {
    path: 'whatsapp-template-reports',
    loadChildren: () => import('./whatsapp-template-reports/whatsapp-template-reports.module').then( m => m.WhatsappTemplateReportsPageModule)
  },
  {
    path: 'voice',
    loadChildren: () => import('./voice-module/voice-module.module').then( m => m.VoiceModuleModule)
  },
  {
    path: 'group',
    loadChildren: () => import('./group-module/group-module.module').then( m => m.GroupModuleModule)
  },
  {
    path: 'device',
    loadChildren: () => import('./device-module/device-module.module').then( m => m.DeviceModuleModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
