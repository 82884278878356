// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button ion-icon {
  font-size: 13px;
}

.profile ion-icon {
  margin-right: 4px;
}

.signout ion-icon {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 15px;
}

ion-item {
  --min-height: 40px !important;
}

ion-avatar ion-icon {
  font-size: 40px !important;
  color: #b9b6b6;
}

ion-item ion-label {
  font-size: 14px;
  color: #6e6b7b;
}

h2 {
  color: #5366ff;
  font-size: 18px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/profile/profile.component.scss"],"names":[],"mappings":"AAAA;EACQ,eAAA;AACR;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,6BAAA;AAAJ;;AAGA;EACI,0BAAA;EACA,cAAA;AAAJ;;AAGA;EACI,eAAA;EACA,cAAA;AAAJ;;AAGA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":["ion-button ion-icon {\r\n        font-size: 13px;\r\n    \r\n}\r\n\r\n.profile ion-icon {\r\n    margin-right: 4px;\r\n}\r\n\r\n.signout ion-icon {\r\n    margin-left: 4px;\r\n}\r\n\r\n.ml-2 {\r\n    margin-left: 15px;\r\n}\r\n\r\nion-item {\r\n    --min-height: 40px !important;\r\n}\r\n\r\nion-avatar ion-icon {\r\n    font-size: 40px !important;\r\n    color: #b9b6b6;\r\n}\r\n\r\nion-item ion-label {\r\n    font-size: 14px;\r\n    color: #6e6b7b;\r\n}\r\n\r\nh2 {\r\n    color: #5366ff;\r\n    font-size: 18px;\r\n    font-weight: 600;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
